<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.use") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row dense v-if="!isLoading">
        <v-col cols="12" md="6">
          <WarehouseUse
            class="pr-md-5"
            :supplier="supplier"
            :using-ids="warehouseUsingIds"
          />
        </v-col>
        <v-col cols="12" md="6">
          <PosUse
            class="pl-md-5"
            :supplier="supplier"
            :using-ids="posUsingIds"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "WpSupplierDialog",
  components: {
    WarehouseUse: () => import("@/components/supply/WarehouseUse"),
    PosUse: () => import("@/components/supply/PosUse"),
  },
  props: {
    supplier: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    warehouseUsingIds: [],
    posUsingIds: [],
    isLoading: true,
  }),
  computed: {},
  watch: {},
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    getList: debounce(function () {
      httpClient
        .post("/supplier-using-list", { id_supplier: this.supplier.id })
        .then(({ data }) => {
          [...data].forEach((item) => {
            if (item.id_warehouse) {
              this.warehouseUsingIds.push(item.id_warehouse);
            }
            if (item.id_pos) {
              this.posUsingIds.push(item.id_pos);
            }
          });
          this.isLoading = false;
        });
    }, 500),
  },
};
</script>

<style scoped></style>
